import { useAuthentication } from '../hooks/useAuthentication';

export const useParseValuesForInquiry = () => {
	const { userIdentifier } = useAuthentication();

	const parseValuesForInquiry = (values) => parseValuesForInquirySimple(values, userIdentifier);

	return { parseValuesForInquiry };
};

const getLegs = (values) => {
	let legs = [];

	if (values.flightType.constantKey === 'FLIGHTTYPE_ROUND_TRIP') {
		legs = [
			{
				departureAirport: { id: values.legs[0].departureAirport.value.id },
				destinationAirport: { id: values.legs[0].destinationAirport.value.id },
				departureAt: values.legs[0].departureAt,
			},
			{
				departureAirport: { id: values.legs[0].destinationAirport.value.id },
				destinationAirport: { id: values.legs[0].departureAirport.value.id },
				departureAt: values.legs[0].returnAt,
			},
		];
	} else {
		legs = values.legs.map((leg) => ({
			departureAirport: { id: leg.departureAirport.value.id },
			destinationAirport: { id: leg.destinationAirport.value.id },
			departureAt: leg.departureAt,
		}));
	}
	return legs;
};

export const getInquiryLegs = (values) => {
	let legs = [];

	if (values.flightType.constantKey === 'FLIGHTTYPE_ROUND_TRIP') {
		legs = [
			{
				departureAirport: values.inquiryLegs[0].departureAirport,
				destinationAirport: values.inquiryLegs[0].destinationAirport,
				departureAt: values.inquiryLegs[0].departureAt,
			},
			{
				departureAirport: values.inquiryLegs[0].destinationAirport,
				destinationAirport: values.inquiryLegs[0].departureAirport,
				departureAt: values.inquiryLegs[0].returnAt,
			},
		];
	} else {
		legs = values.inquiryLegs.map((leg) => ({
			departureAirport: leg.departureAirport,
			destinationAirport: leg.destinationAirport,
			departureAt: leg.departureAt,
		}));
	}
	return legs;
};

export const getEstimationLegsFromRaF = (values) => {
	let legs = [];

	if (values.trip.flightType.constantKey === 'FLIGHTTYPE_ROUND_TRIP') {
		legs = [
			{
				departureAirport: { label: values.trip.legs.roundTrip.departure.label },
				destinationAirport: { label: values.trip.legs.roundTrip.destination.label },
				departureAt: values.trip.legs.roundTrip.dateFrom,
			},
		];
	} else if (values.trip.flightType.constantKey === 'FLIGHTTYPE_ONE_WAY') {
		legs = [
			{
				departureAirport: { label: values.trip.legs.oneWay.departure.label },
				destinationAirport: { label: values.trip.legs.oneWay.destination.label },
				departureAt: values.trip.legs.oneWay.dateFrom,
			},
		];
	} else {
		legs = values.trip.legs.multiLeg.map((leg) => ({
			departureAirport: { id: leg.departure.label },
			destinationAirport: { id: leg.destination.label },
			departureAt: leg.dateFrom,
		}));
	}
	return legs;
};

export const parseValuesForInquirySimple = (values, userIdentifier?: string) => {
	let inquiryLegs = getLegs(values);

	return {
		inquiryLegs,
		numberOfSeats: values.numberOfSeats.passengers,
		flightType: { id: values.flightType.value, name: values.flightType.label },
		aircraftDetailedTypes: values.aircraftType?.aircraft?.map((value) => ({ id: value })) ?? [],
		userIdentifier,
	};
};

export const parseValuesForEstimation = (values, userIdentifier?: string) => {
	const estimationLegs = getLegs(values);

	return {
		estimationLegs,
		numberOfSeats: values.numberOfSeats.passengers,
		flightType: { id: values.flightType.value, name: values.flightType.label },
		aircraftDetailedTypes: values.aircraftType?.aircraft?.map((value) => ({ id: value })) ?? [],
		userIdentifier,
	};
};

export const parseValuesForInquiryFromEstimation = (values, userIdentifier?: string) => {
	const inquiryLegs = values.legs.map((leg) => ({
		departureAirport: { id: leg.departureAirport.id },
		destinationAirport: { id: leg.destinationAirport.id },
		departureAt: leg.departureAt,
	}));

	return {
		inquiryLegs,
		numberOfSeats: values.numberOfSeats,
		flightType: { id: values.flightType.id, name: values.flightType.name },
		aircraftDetailedTypes: values.aircraftType?.map((value) => ({ id: value })) ?? [],
		userIdentifier,
		estimation: {
			id: values.id,
			flightType: { id: values.flightType.id, name: values.flightType.name },
		},
	};
};
