import { useQueryClient } from '@tanstack/react-query';

import { getAccessToken, useApiEstimationsIdGet, useApiEstimationsPost } from '@fllite-fe/api';

import { useFlashMessage } from '../components/FlashMessages';
import { MeUser } from '../types';
import { USER_QUERY_KEY_NAME } from './useAuthentication';

export const QUERY_KEY_ESTIMATION_NAME = ['estimations'];

export const useEstimations = (
	{ estimationId }: { estimationId?: string } = { estimationId: undefined },
) => {
	const accessToken = getAccessToken();
	const queryClient = useQueryClient();
	const { FlashMessageTypes, pushFlashMessage } = useFlashMessage();

	const {
		data: estimation,
		isLoading,
		refetch,
		isFetched,
	} = useApiEstimationsIdGet(estimationId, {
		query: {
			enabled: !!estimationId,
			queryKey: [QUERY_KEY_ESTIMATION_NAME, estimationId],
		},
	});

	const { mutateAsync: estimate, isPending: estimateIsLoading } = useApiEstimationsPost({
		mutation: {
			onSuccess: (res) => {
				const userQueryKey = [USER_QUERY_KEY_NAME, accessToken];
				const user: MeUser = queryClient.getQueryData(userQueryKey);

				if (user?.id) {
					queryClient.setQueryData(userQueryKey, { ...user, lastEstimation: { id: res.id } });
				}

				queryClient.setQueryData([QUERY_KEY_ESTIMATION_NAME, res.id], res);
			},
			onError: () => {
				pushFlashMessage({
					type: FlashMessageTypes.Error,
					text: 'An error occurred',
				});
			},
		},
	});

	return {
		estimation,
		estimate,
		isLoading,
		estimateIsLoading,
		refetch,
		isFetched,
	};
};
