import { ButtonHTMLAttributes, FC } from 'react';

import styled from '@emotion/styled';
import { Box, Stack } from '@mui/material';
import CircularProgress, {
	circularProgressClasses,
	CircularProgressProps,
} from '@mui/material/CircularProgress';
import styledMap from 'styled-map';

const background = styledMap`
	default: ${({ theme }) => theme.colors.primaryDarkest} ${({ theme }) => theme.gradients.primary};
	primary: ${({ theme }) => theme.colors.primaryDarkest} ${({ theme }) => theme.gradients.primary};
	secondary: ${({ theme }) => theme.colors.secondary} ${({ theme }) => theme.gradients.secondary};
	outline: transparent;
	outlinePrimary: transparent;
	outlineFilled: white;
	outlinePrimaryFilled: white;
  	iconOnly: transparent;
  	text: transparent;
`;

const backgroundHover = styledMap`
	default: ${({ theme }) => theme.colors.primaryDarkest};
	primary: ${({ theme }) => theme.colors.primaryDarkest};
	secondary: ${({ theme }) => theme.colors.secondary};
	outline: #fff;
	outlinePrimary:  transparent;
	outlineFilled: white;
	outlinePrimaryFilled: white;
  	iconOnly: transparent;
  	text: transparent;
`;

const color = styledMap`
	default: #FFF;
	primary: #FFF;
	secondary: #FFF;
	outline: #FFF;
	outlinePrimary: ${({ theme }) => theme.colors.primary};
	outlineFilled: ${({ theme }) => theme.colors.primary};
	outlinePrimaryFilled: ${({ theme }) => theme.colors.primary};
  	iconOnly: ${({ theme }) => theme.colors.primary};
  	text: ${({ theme }) => theme.colors.primary};
`;

const colorHover = styledMap`
    default: #FFF;
    primary: #FFF;
    secondary: #FFF;
    outline: ${({ theme }) => theme.colors.primaryDarkest};
    outlinePrimary: ${({ theme }) => theme.colors.primaryDarkest};
    outlineFilled: ${({ theme }) => theme.colors.primaryDarkest};
    outlinePrimaryFilled: ${({ theme }) => theme.colors.primaryDarkest};
    iconOnly: ${({ theme }) => theme.colors.primary};
    text: ${({ theme }) => theme.colors.primaryDarkest};
`;

const border = styledMap`
	default: none;
	primary: none;
	secondary: none;
	outline: 1px solid #fff;
	outlinePrimary: 1px solid ${({ theme }) => theme.colors.primary};
	outlineFilled: 1px solid ${({ theme }) => theme.colors.grayLight};
	outlinePrimaryFilled: 1px solid ${({ theme }) => theme.colors.primary};
  	iconOnly: none;
  	text: none;
`;

const borderHover = styledMap`
	default: none;
	primary: none;
	secondary: none;
	outline: 1px solid #fff;
	outlinePrimary: 1px solid ${({ theme }) => theme.colors.primaryDarkest};
	outlineFilled: 1px solid ${({ theme }) => theme.colors.gray};
	outlinePrimaryFilled: 1px solid ${({ theme }) => theme.colors.primaryDarkest};
  	iconOnly: none;
  	text: none;
`;

const cursor = styledMap`
	default: pointer;
	disabled: not-allowed;
`;

const padding = styledMap`
  default: 9px 20px;
  medium: 5px 20px;
  iconOnly: 0;
  text: 0;
  smallSize: 0 15px;
  extraSmall: 0px 8px;
`;

const mediaHeight = styledMap`
  default: 42px;
  medium: 34px;
  small: 27px;
  extraSmall: 25px;
`;

const mediaPadding = styledMap`
  default: 10px 20px;
  medium: 5px 20px;
  small: 0px;
  extraSmall: 0px 8px;
`;

const minWidth = styledMap`
  default: 190px;
  medium: 170px;
  iconOnly: 0;
  text: 0;
  smallSize: 10px;
  extraSmall: 94px;
`;

const minHeight = styledMap`
  default: 42px;
  medium: 34px;
  iconOnly: 0;
  text: 0;
  smallSize: 42px;
  extraSmall: 25px;
`;

const opacity = styledMap`
  default: 1;
  disabled: 0.4;
`;

const filter = styledMap`
  default: none;
  disabled: grayScale(0.9);
`;

const radius = styledMap`
  default: ${({ theme }) => theme.radius.buttonRadius};
  smallRadius: ${({ theme }) => theme.radius.smallBoxRadius};
`;

const fontSize = styledMap`
  default: 14px;
  smallFont: 14px;
  extraSmall: 14px;
`;

const mediaFontSize = styledMap`
  default: 14px;
  extraSmall: 14px;
`;

const buttonProps = [
	'primary',
	'secondary',
	'outline',
	'outlinePrimary',
	'outlineFilled',
	'outlinePrimaryFilled',
	'text',
	'iconOnly',
	'small',
	'smallRadius',
	'smallFont',
	'smallSize',
	'extraSmall',
	'medium',
] as const;

// eslint-disable-next-line prettier/prettier
export interface ButtonProps extends Partial<Record<typeof buttonProps[number], boolean>> {
	type?: 'submit' | 'button';
}

// @TODO: Fix typings, remove prop forwarding.
export const StyledButton = styled('button', {
	// eslint-disable-next-line prettier/prettier
	shouldForwardProp: (prop: typeof buttonProps[number] | 'as') => !buttonProps.includes(prop as typeof buttonProps[number]) && prop !== 'as',
})<ButtonProps>`
	font-family: ${({ theme }) => theme.typography.fontFamily};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-height: ${minHeight};
	min-width: ${minWidth};
	color: ${color};
	background: ${background};
	font-size: ${fontSize};
	font-weight: 500;
	border: ${border};
	opacity: ${opacity};
	filter: ${filter};
	box-sizing: border-box;
	border-radius: ${radius};
	cursor: ${cursor};
	padding: ${padding};
	transition:
		background ${({ theme }: any) => theme.transition.baseTransition},
		border ${({ theme }: any) => theme.transition.baseTransition},
		color ${({ theme }: any) => theme.transition.baseTransition};

	&:focus,
	&:active {
		outline: none;
	}

	&:hover {
		background: ${backgroundHover};
		border: ${borderHover};
		color: ${colorHover};
	}

	${({ theme }) => theme.breakpoints.down('md')} {
		padding: ${mediaPadding};
		min-height: ${mediaHeight};
		font-size: ${mediaFontSize};
	}
`;

const CIRCLE_HEIGHT = 18;

interface StyledCircularProgressProps extends CircularProgressProps {
	isBlue: boolean;
}

const StyledCircularProgress: FC<StyledCircularProgressProps> = (props) => (
	<Box sx={{ position: 'relative' }}>
		<CircularProgress
			variant="determinate"
			sx={{
				color: props.isBlue ? '#0056BE' : '#fff',
				opacity: 0.5,
				position: 'absolute',
				left: `calc(50% - ${CIRCLE_HEIGHT / 2}px)`,
				top: `calc(50% - ${CIRCLE_HEIGHT / 2}px)`,
			}}
			size={CIRCLE_HEIGHT}
			thickness={8}
			{...props}
			value={100}
		/>
		<CircularProgress
			variant="indeterminate"
			disableShrink
			sx={{
				color: props.isBlue ? '#0056BE' : '#fff',
				animationDuration: '1000ms',
				position: 'absolute',
				left: `calc(50% - ${CIRCLE_HEIGHT / 2}px)`,
				top: `calc(50% - ${CIRCLE_HEIGHT / 2}px)`,
				[`& .${circularProgressClasses.circle}`]: {
					strokeLinecap: 'round',
					strokeDasharray: '30px 120px',
				},
			}}
			size={CIRCLE_HEIGHT}
			thickness={8}
			{...props}
		/>
	</Box>
);

interface ExtendedButtonProps extends ButtonProps, ButtonHTMLAttributes<HTMLButtonElement> {
	children?: any;
	showChildrenDuringLoading?: boolean;
	isLoading?: boolean;
	blueLoading?: boolean;
	type?: 'button' | 'submit';
	sx?: any;
}

export const Button: FC<ExtendedButtonProps> = ({
	type = 'button',
	isLoading = false,
	blueLoading = false,
	children,
	showChildrenDuringLoading,
	...otherProps
}) => (
	<StyledButton type={type} {...otherProps}>
		{isLoading && showChildrenDuringLoading ? (
			<Stack direction="row" gap={2}>
				<StyledCircularProgress isBlue={blueLoading} />
				{children}
			</Stack>
		) : isLoading ? (
			<StyledCircularProgress isBlue={blueLoading} />
		) : (
			children
		)}
	</StyledButton>
);
